import { isPlatform } from "@ionic/react";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS, logError } from "@src/appV2/lib/analytics";
import { useEffect, useState } from "react";

import { getZendeskJWTMessagingSDK } from "../api/zendesk";

declare global {
  interface Window {
    zE: (x: string, y: string, z?: (callback: (token?: string) => void) => void) => void;
  }
}

export function useZendeskMessaging() {
  const [webWidgetRendered, setWebWidgetRendered] = useState<boolean>(false);

  // Doc ref - https://developer.zendesk.com/documentation/zendesk-web-widget-sdks/sdks/web/sdk_api_reference/#authentication
  useEffect(() => {
    if (isPlatform("capacitor")) {
      return;
    }

    if (!webWidgetRendered) {
      return;
    }
    window.zE("messenger", "loginUser", function (callback) {
      getZendeskJWTMessagingSDK()
        .then(callback)
        .catch((error) => {
          logError(APP_V2_APP_EVENTS.ZENDESK_LOGIN_ERROR, { error });
        });
    });
    window.zE("messenger", "hide");
  }, [webWidgetRendered]);

  useEffect(() => {
    if (isPlatform("capacitor")) {
      return;
    }

    // Doc ref - https://support.zendesk.com/hc/en-us/articles/4500748175258-Installing-the-Web-Widget-in-a-website-or-Help-Center
    const script = document.createElement("script");
    script.id = environmentConfig.REACT_APP_ZENDESK_WEB_WIDGET_ID;
    script.src = environmentConfig.REACT_APP_ZENDESK_MESSAGING_WIDGET_URL;
    document.body.appendChild(script);
    script.addEventListener("load", () => setWebWidgetRendered(true));

    return () => {
      window.zE("messenger", "logoutUser");
      document.body.removeChild(script);
    };
  }, []);
}
